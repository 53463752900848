@import "src/styles/variable";

.timecard-reminder {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  width: 600px;
  height: 263px;
  padding: 35px 40px;
  margin-left: 40px;

  .timecard-reminder-title {
    color: #013d4f;
    font-size: 20px;
    font-weight: 700;
  }

  .timecard-reminder-info {
    margin-top: 30px;
    font-size: 15px;
  }

  .send-btn {
    margin-top: 15px;
    width: 98px;
    height: 40px;
  }
}
