@import "src/styles/variable";
@import "src/styles/common";

.root-element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $background-blue;
}
